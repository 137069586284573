'use strict';

const Papa = require('papaparse');

const Locator = {
    source: '/static/stores.credelio.csv',
    google: {
        api: 'https://www.google.com/maps/search/?api=1'
    },
    stores: [],
    query: {
        Province: '',
        Canton: '',
        Results: []
    }
}

const hideAdditionalLayer = () => {
    $('.stores').hide();
    $('.btn-store-no-found').hide();
    $('.btn-store-warning').hide();
}

const showNotFoundLayer = () => {
    $('.btn-store-no-found').show()
}

const showResultsLayer = () => {
    $('.stores').show()
}

const showWarningLayer = () => {
    $('.btn-store-warning').show()
}

const getProvinceOptions = (stores) => {
    // group available entities as options
    return _.keys(_.countBy(stores, (stores) => {
        return stores.Provincia;
    }));
}

const getCantonOptions = (stores) => {
    // group available entities as options
    return _.keys(_.countBy(stores, (stores) => {
        return stores.Canton;
    }));
}

const filterByProvince = (stores, province) => {
    // run query to obtain stores by province
    return stores.filter((store) => {
        return store.Provincia == province;
    });
}

const filterByCanton = (stores, canton) => {
    // run query to obtain stores by canton
    return stores.filter((store) => {
        return store.Canton == canton;
    });
}

const setProvinceOptions = (options) => {
    // set default option
    $('#province-filter')
        .append($('<option></option>').attr('value', '').text('Provincia'));

    $.each(options, (_, value) => {
        $('#province-filter')
            .append($('<option></option>').attr('value', value).text(value));
    });
}

const setCantonOptions = (options) => {
    // reset options
    $('#canton-filter').find('option').remove();

    // set default option
    $('#canton-filter')
        .append($('<option></option>').attr('value', '').text('Cantón'));

    $.each(options, (_, value) => {
        $('#canton-filter')
            .append($('<option></option>').attr('value', value).text(value));
    });
}

const setResults = () => {
    // reset results
    $('.store__container:first').empty();

    // set headline
    $('.store__headline:first')
    .text(`Resultados en ${Locator.query.Province}, ${Locator.query.Canton}:`);

    // append results
    $.each(Locator.query.Results, (_, store) => {
    $('.store__container:first')
        .append($(`
        <div class="store-entity">
            <h4>${store.Nombre}</h4>
            <p class="stores__phone"><strong>Teléfono:</strong> <a href="tel:${store.Telefono}">${store.Telefono}</a></p>
            <p class="stores__address"><strong>Direccion:</strong> ${store.Direccion}</p>
            <p class="stores__address-link">
                <a title="${store.Nombre}" target="_blank" rel="noreferrer" href="${Locator.google.api}&query=${store.Latitud},${store.Longitud}">Abrir en Google Maps</a>
            </p>
        </div>`).html());
    });
}

const handleProvince = (event) => {
    // set search parameter
    Locator.query.Province = event.target.value;
    Locator.query.Canton = '';

    // save results and update search parameter
    Locator.query.Results = filterByProvince(
        Locator.stores,
        Locator.query.Province
    );

    // get canton and set selected options
    setCantonOptions(
        getCantonOptions(Locator.query.Results)
    );
}

const handleCanton = (event) => {
    // set search parameter
    Locator.query.Canton = event.target.value;

    // save results and update search parameter
    Locator.query.Results = filterByCanton(
        Locator.stores,
        Locator.query.Canton
    );
}

const handleSearch = (event) => {
    event.preventDefault();

    // reset to display view
    hideAdditionalLayer();

    if (Locator.stores.length < 1) {
        // show message if not stores in DB
        showNotFoundLayer();
        return;
    }

    if ([
        Locator.query.Province,
        Locator.query.Canton
    ].includes('')) {
        // show message if not option selected
        showWarningLayer();
        return;
    }

    if (Locator.query.Results.length < 1) {
        // show message if not results
        showNotFoundLayer();
        return;
    }

    setResults();
    showResultsLayer();

    console.log(Locator.query);
}

$(document).ready(() => {
    // reset display view
    hideAdditionalLayer();

    Papa.parse(Locator.source, {
        complete: (res) => {
            // save parsed stores
            Locator.stores = res.data;

            // get province and set selected options
            setProvinceOptions(
                getProvinceOptions(Locator.stores)
            );

            // bind events to handlers
            $('#province-filter').on('change', handleProvince);
            $('#canton-filter').on('change', handleCanton);
            $('#locator-search').on('click', handleSearch);
        },
        header: true,
        dynamicTyping: true,
        download: true,
    });
});
